import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";

import CustomSelectOptions from "../../formComponents/CustomSelectOptions";
import {
    CHART_TYPES,
    SERIES_DATA_OPTIONS,
} from "../../../constants/widgetsTypes";
import LoadingButton from "../../LoadingButton";

type EditGraphWidgetComponentProps = {
    onSubmit: any;
    initialValue?: any;
    loading: boolean;
};

const EditGraphWidgetComponent: React.FC<EditGraphWidgetComponentProps> = ({
    onSubmit,
    loading,
    initialValue,
}) => {
    const [form] = Form.useForm();

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={(values: any) => onSubmit(values, form)}
            initialValues={initialValue}
        >
            <Form.Item
                label="Chart Type"
                name="chartType"
                rules={[
                    {
                        required: true,
                        message: "Please select the Chart type!",
                    },
                ]}
            >
                <CustomSelectOptions
                    options={CHART_TYPES}
                    onChange={(val: any) =>
                        form.setFieldValue("chartType", val)
                    }
                />
            </Form.Item>
            <Form.Item label="X Axis Title" name="xAxisTitle">
                <Input placeholder="Enter X Axix Title" />
            </Form.Item>
            <Form.Item label="Y Axis Title" name="yAxisTitle">
                <Input placeholder="Enter Y Axix Title" />
            </Form.Item>
            <Form.Item
                label="Series Data"
                name="dataType"
                rules={[
                    {
                        required: true,
                        message: "Please select the Series Data!",
                    },
                ]}
            >
                <CustomSelectOptions
                    options={SERIES_DATA_OPTIONS}
                    onChange={(val: any) => form.setFieldValue("dataType", val)}
                />
            </Form.Item>
            <Form.Item>
                <LoadingButton
                    loading={loading}
                    title={_.isEmpty(initialValue) ? "Save" : "Update"}
                />
            </Form.Item>
        </Form>
    );
};

export default EditGraphWidgetComponent;
