import useSWR from 'swr';
import axios from 'axios';
import toastr from 'toastr';
import { logout } from './store/slice/authSlice';
import store from './store/store';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const client2 = axios.create({
  baseURL: process.env.REACT_APP_OPERATIONAL_URL, // Ensure this is correctly set in your .env
  timeout: 60000,
  headers,
});

// Interceptors for handling responses and requests
client2.interceptors.response.use(
  response => response, // Pass the response as is if no error
  error => {
    if (error.response) {
      const { status, data } = error.response;

      switch (status) {
        case 400: // Bad Request
          toastr.error(data.message || 'Bad request.');
          break;
        case 401: // Unauthorized
          store.dispatch(logout()); // Dispatch logout or handle re-authentication
          toastr.error('Unauthorized access');
          break;
        case 403: // Forbidden
          toastr.error(data.message || 'You do not have permission to perform this action.');
          break;
        case 404: // Not Found
          toastr.error('The requested resource was not found.');
          break;
        case 405: // Method Not Allowed
          toastr.error('HTTP method not allowed.');
          break;
        case 422: // Validation Error
          if (data.errors) {
            Object.values(data.errors).forEach(item => toastr.error(item[0]));
          } else {
            toastr.error(data.message || 'Validation error occurred.');
          }
          break;
        case 429: // Too Many Requests
          toastr.warning('Too many requests. Please slow down.');
          break;
        case 500: // Internal Server Error
          toastr.error('Something went wrong');
          break;
        case 503: // Service Unavailable
          toastr.error('Service is temporarily unavailable. Please try again later.');
          break;
        default: // Other errors
          toastr.error('An unexpected error occurred.');
          break;
      }
    } else if (error.request) {
      // Handle cases where no response is received
      toastr.error('No response from the server. Please check your network connection.');
    } else {
      // Handle other errors
      toastr.error(`Something went wrong`);
    }

    return Promise.reject(error.response);
  }
);


// Interceptor to attach the authorization token
client2.interceptors.request.use(
  config => {
    const accessToken = store.getState().auth.token?.token;
    const email = store.getState().user?.user?.email;
    const tenant = store.getState().user?.user?.company_id;

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.tenant = `${email}, ${tenant}`;
    }
    return config;
  },
  err => Promise.reject(err)
);

// Function to upload media files
const uploadMedia = async (files, url, fields = {}) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files[]', file);
  });

  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const response = await client2.post(url, formData);
  return response.data; // Return response data directly for easier handling
};

// Function to download a blob file
const downloadBlobFile = async (apiUrl, fileName) => {
  const response = await client2.get(apiUrl, { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// Custom hook for making requests
export default function useRequest(request, { initialData, ...config } = {}) {
  const { data: response, error, isValidating, mutate: originalMutate } = useSWR(
    request && JSON.stringify(request),
    () => client2(request || {}),
    {
      ...config,
      suspense: false,
      revalidateOnFocus: false,
      revalidateIfStale: true,
      revalidateOnMount: true,
      initialData: initialData && {
        status: 200,
        statusText: 'InitialData',
        headers: {},
        data: initialData,
      },
    }
  );

  return {
    data: response?.data, // Safe access to response data
    response,
    error,
    isValidating,
    mutate: update => originalMutate(response => ({
      ...response,
      data: { ...response.data, ...update }, // Merge updated data
    })),
  };
}

// Exporting client2 and other functions
export { client2, uploadMedia, downloadBlobFile };
