import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CustomDatePicker from "../CustomDatePicker";
import { client2 } from "../../operationalRequest";

interface GraphWidgetProps {
    dateFilter?: boolean;
    chartType: "bar" | "line" | "pie";
    // series: ChartSeries | NonAxisChartSeries;
    xAxisTitle?: string;
    yAxisTitle?: string;
    dataType?: string;
}

const GraphWidget: React.FC<GraphWidgetProps> = (props) => {
    const series: any[] = [];
    const [selectedDate, setSelectedDate] = useState<string | string[]>("");
    const [selectedDateType, setSelectedDateType]= useState<string>("");
    const [data, setData] = useState<any[]>([]);
    

    const options = {
        chart: {
            borderRadius: 100,
            responsive: true,
        },
        noData: {
            text: "No data available",
            align: "center",
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: "14px",
            },
        },
        legend: {
            position: 'top',
        },
        xaxis: {
            title: {
                text: props.xAxisTitle,
            },
        },
        yaxis: {
            title: {
                text: props.yAxisTitle,
            },
            labels: {
                formatter: (value: any) => parseFloat(value).toFixed(2),
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: [2, 2, 2, 2],
        },
    };

    const fetchData = async (dataType: string) => {
        const response = await client2.get('api/widgets/charts?reportType=' + dataType + '&dateType=' + selectedDateType) ;
        setData(response.data.data ?? []);
    };

    useEffect(() => {
        if (props.dataType) {
            fetchData(props.dataType);
        }
    }, [props.dataType, props.chartType, selectedDate]);

    const handleDateChange = (dateType:string, date: string | string[]) => {
        setSelectedDate(date);
        setSelectedDateType(dateType)
    };

    return (
        <>
            <div className="mt-4">
            {
                props.dateFilter ? <CustomDatePicker onDateChange={handleDateChange} /> : <></>
            }
            </div>
            <Chart
                type={props.chartType}
                options={options as any}
                series={data}
                width="100%"
                height="100%"
            />
        </>
    );
};

export default GraphWidget;
