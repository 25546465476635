import React from 'react';
import { Button, Image, Segmented, Table } from 'antd';

const dataSource = [
    {
        key: '1',
        roof: 'Left-Roof',
        azimuth: '93°',
        tilt: '10°',
        installedCapacity: '35 kWp',
        inverterCapacity: '28 kWp',
    },
    {
        key: '2',
        roof: 'Left-Roof',
        azimuth: '273°',
        tilt: '10°',
        installedCapacity: '35 kWp',
        inverterCapacity: '28 kWp',
    },
    {
        key: '3',
        roof: 'Right-Roof',
        azimuth: '96°',
        tilt: '10°',
        installedCapacity: '15 kWp',
        inverterCapacity: '12 kWp',
    },
    {
        key: '4',
        roof: 'Right-Roof',
        azimuth: '276°',
        tilt: '10°',
        installedCapacity: '15 kWp',
        inverterCapacity: '12 kWp',
    },
];

const columns = [
    {
        title: 'Roof',
        dataIndex: 'roof',
        key: 'roof',
    },
    {
        title: 'Azimuth',
        dataIndex: 'azimuth',
        key: 'azimuth',
    },
    {
        title: 'Tilt',
        dataIndex: 'tilt',
        key: 'tilt',
    },
    {
        title: 'Installed Capacity',
        dataIndex: 'installedCapacity',
        key: 'installedCapacity',
    },
    {
        title: 'Inverter Capacity',
        dataIndex: 'inverterCapacity',
        key: 'inverterCapacity',
    },
];

const ExampleTable = () => {
    return <Table dataSource={dataSource} columns={columns} pagination={false} style={{ width: '700px', margin: '0 auto' }} />;
};

interface DetailsProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Details: React.FC<DetailsProps> = ({ setOpen }) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold', color: '#333' }}>Azimuth (Orientation) Details</h2>

            {/* Azimuth Image 1 */}
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <Image
                    width={700}
                    src="/img/azimuth_tilt.png"
                    alt="Azimuth Diagram"
                    style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
                <Segmented
                    options={[
                        {
                            label: (
                                <div style={{ padding: 4 }}>
                                    <div>South</div>
                                    <div>180°</div>
                                </div>
                            ),
                            value: '180°',
                        },
                        {
                            label: (
                                <div style={{ padding: 4 }}>
                                    <div>West</div>
                                    <div>90°</div>
                                </div>
                            ),
                            value: '90°',
                        },
                        {
                            label: (
                                <div style={{ padding: 4 }}>
                                    <div>East</div>
                                    <div>270°</div>
                                </div>
                            ),
                            value: '270°',
                        },
                        {
                            label: (
                                <div style={{ padding: 4 }}>
                                    <div>North</div>
                                    <div>0°</div>
                                </div>
                            ),
                            value: '0°',
                        },
                    ]}
                />
            </div>

            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <p style={{ fontSize: '16px', lineHeight: '1.6', marginTop: '20px' }}>
                    We have defined that a southern azimuth equals 180°. A northern azimuth equals 0° or 360°.
                    An azimuth to the west is 270° and to the east 90°. There is no difference between the
                    Northern or Southern hemisphere. PV-Systems in Australia facing north have an azimuth of 0°.
                </p>
            </div>

            {/* Azimuth Image 2 and 3 */}
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <Image
                    width={700}
                    src="/img/azimuth2.png"
                    alt="Azimuth Diagram 2"
                    style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                />
                <p style={{ margin: '20px 0' }}></p>
                <Image
                    width={700}
                    src="/img/azimuth3.png"
                    alt="Azimuth Diagram 3"
                    style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                />
            </div>

            <h4 style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '30px', textAlign: 'center' }}>Example Scenario</h4>
            <div style={{ fontSize: '20px', marginTop: '30px', textAlign: 'center' }}>
                <ExampleTable />
            </div>

            <div style={{ textAlign: 'center', marginTop: '30px' }}>
                <Button
                    key="close"
                    onClick={handleClose}
                    style={{
                        backgroundColor: '#5F5FC1',
                        color: '#fff',
                        borderRadius: '4px',
                        padding: '10px 20px',
                        fontSize: '16px',
                        border: 'none',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    Close
                </Button>
            </div>
        </>
    );
};

export default Details;
