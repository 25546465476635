import { Tooltip } from 'antd'
import { PlusIcon } from 'lucide-react'
import React from 'react'

type AddButtonProps = {
    title: string;
    setOpen: any;
}
const AddButton: React.FC<AddButtonProps> = ({title, setOpen}) => {
  return (
    <Tooltip title={title}>
        <a onClick={() => setOpen(true)}>
          <PlusIcon
            size={30}
            color="white"
            strokeWidth={2}
            className=" rounded-full cursor-pointer bg-gray-500 transition duration-300 ease-in-out hover:bg-gray-400 hover:text-white"
          />
        </a>
      </Tooltip>
  )
}

export default AddButton