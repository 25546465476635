import { Button, Popconfirm, Tooltip } from "antd";
import React from "react";
import { client2 } from "../../operationalRequest";
import { Trash2Icon } from "lucide-react";
import _ from "lodash";
import toastr from "toastr";
import { useSelector } from "react-redux";

type DeleteButtonProps = {
  model: string;
  id: string | number;
  setMutate: any;
  title: string;
  permission: string;
};
const DeleteButton: React.FC<DeleteButtonProps> = ({
  model,
  id,
  setMutate,
  title,
  permission,
}) => {
  const { role } = useSelector((state: any) => state.role);

  // console.log('fasda', role)
  const onDelete = async (id: any) => {
    const response = await client2.delete(`api/${model}/${id}`);
    setMutate({ data: Math.random() });
    toastr.success(`${_.startCase(title)} Deleted Successfully!`);
  };
  return (
    <>
      {(!!role.role.includes("tenant") ||
        !!role.permissions.includes(permission)) && (
        <Popconfirm
          title={`Delete the ${title}`}
          description={`Are you sure to delete this ${title}?`}
          onConfirm={() => onDelete(id)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title={`Delete ${title}`}>
          <Button
            size="small"
            className="bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
          >
            <Trash2Icon />
          </Button>
          </Tooltip>
        </Popconfirm>
      )}
    </>
  );
};

export default DeleteButton;
