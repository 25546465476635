import React, { useEffect, useState } from "react";
import { Table, Space, Tag } from "antd";
import { UPDATE_USER } from "../../../utils/permission";
import PermissionCheck from "../../../components/authorizeComponent/PermissionCheck";
import EditUser from "./EditUser";
const { Column } = Table;

interface DataType {
  key: React.Key;
  id: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  role?: string;
  companyId?: string;
}

interface UserListProps {
  companyId: string;
  users: DataType[];
}

const UserList: React.FC<UserListProps> = ({ companyId, users }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<DataType[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: users.length,
  });

  useEffect(() => {
    const updatedUsers = users.map(user => ({
      ...user,
      key: user.id,
    }));
    setList(updatedUsers);
    setPagination(prev => ({ ...prev, total: updatedUsers.length }));
  }, [companyId, users]);

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  return (
    <div>
      <Table<DataType>
        dataSource={list}
        rowKey="key"
        pagination={pagination}
        onChange={handleTableChange}
      >
        <Column
          title="First Name"
          dataIndex="firstname"
          key="firstName"
          render={(firstName) => <span>{firstName || "Not set"}</span>}
        />
        <Column
          title="Last Name"
          dataIndex="lastname"
          key="lastName"
          render={(lastName) => <span>{lastName || "Not set"}</span>}
        />
        <Column
          title="Email"
          dataIndex="email"
          key="email"
          render={(text) => <span>{text || "N/A"}</span>}
        />
        <Column
          title="Role"
          dataIndex="role"
          key="role"
          render={(text) => {
            const color = text === "tenant" || text === "super-admin" ? "blue" : "green";
            return (
              <Tag color={color}>
                <span>{text || "Unknown"}</span>
              </Tag>
            );
          }}
        />
        <Column
          title="Permission"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <PermissionCheck permission={UPDATE_USER}>
                <EditUser data={record} />
              </PermissionCheck>
            </Space>
          )}
        />
      </Table>
    </div>
  );
};

export default UserList;
