import { useState } from "react";
import { withSize } from "react-sizeme";
import { Button, Switch } from "antd";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import Layout from "../../components/layouts/Layout";
import { WIDGET_TYPES } from "../../utils/widget";
import CustomDrawer from "../../components/CustomDrawer";
import GridLayout from "../../components/GridLayout";

const WidgetPage = () => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => setOpen(!open);

    return (
        <Layout title="Widgets">
            <div
                className={`flex-grow transition-all duration-300  ${
                    open ? "mr-[150px]" : ""
                }`}
            >
                <div className="flex justify-end items-center gap-2 m-4">
                    Customize
                    <Switch size="small" onClick={() => showDrawer()} />
                </div>
                <GridLayout open={open} />
            </div>
            <CustomDrawer open={open}>
                <div className="mt-4">
                    <h3>Select and Drag a Widget:</h3>
                    <div className="flex flex-wrap gap-4">
                        {WIDGET_TYPES.map((widget) => (
                            <div
                                key={widget.value}
                                className={widget.value}
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) =>
                                    e.dataTransfer.setData(
                                        "widget-type",
                                        widget.value,
                                    )
                                }
                            >
                                <img
                                    className="rounded-l"
                                    src={widget.img}
                                    width={100}
                                    height={100}
                                    alt={widget.label}
                                />
                                <span>{widget.label}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </CustomDrawer>
        </Layout>
    );
};

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(
    WidgetPage,
);
