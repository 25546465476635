import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { client2 } from "../../operationalRequest";
import Layout from "../../components/layouts/Layout";
import { Select, Spin } from "antd";

interface PriceData {
  time: string;
  price: string;
}

const MarketPrices: React.FC = () => {
  const [prices, setPrices] = useState<PriceData[]>([]);
  const [chartData, setChartData] = useState({
    series: [{ name: "Price (EUR/MWh)", data: [] as number[] }],
    options: {
      chart: {
        type: "line" as "line", // Explicitly specify "line" as the type
        height: 350,
      },
      xaxis: {
        categories: [] as string[],
        title: { text: "Hour of the day" },
        labels: { rotate: -90, style: { fontSize: "10px" }, offsetY: 5 },
      },
      yaxis: {
        title: { text: "Price (EUR/MWh)" },
        labels: { formatter: (value: number) => value.toFixed(2) },
      },
      tooltip: {
        y: { formatter: (value: number) => `${value.toFixed(2)} EUR/MWh` },
      },
      stroke: { curve: "stepline", width: 2 },
      colors: ["#FF9900"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          gradientToColors: ["#FFE0B3"],
          stops: [0, 0],
        },
      },
      annotations: {
        xaxis: [] as any[], // Start with an empty array for annotations
      },
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("10YGR-HTSO-----Y");

  const countries = [
    { value: "10YGR-HTSO-----Y", label: "Greece" },
    { value: "10YFR-RTE------C", label: "France" },
    { value: "10YES-REE------0", label: "Spain" },
    { value: "10Y1001A1001A44P", label: "Italy" },
  ];

  useEffect(() => {
    const fetchPrices = async () => {
      setLoading(true);
      try {
        const response = await client2.get(
          `/api/market-price/day-ahead?country=${selectedCountry}`
        );

        if (response.status === 200) {
          const priceData: PriceData[] = response.data.data;
          setPrices(priceData);

          // Prepare X-axis categories (time) and Y-axis data (prices)
          const times = priceData.map((data) =>
            new Date(data.time).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
          );
          const priceValues = priceData.map((data) => parseFloat(data.price));

          // Find the position of midnight in the times array
          const midnightIndex = times.findIndex((time) => time === "00:00");

          // Update chart data
          setChartData({
            series: [{ name: "Price (EUR/MWh)", data: priceValues }],
            options: {
              ...chartData.options,
              xaxis: { ...chartData.options.xaxis, categories: times },
              annotations: {
                xaxis:
                  midnightIndex !== -1
                    ? [
                        {
                          x: times[midnightIndex], // Add a vertical marker at midnight
                          borderColor: "#FF0000",
                          label: {
                            style: {
                              color: "#FFFFFF",
                              background: "#FF0000",
                            },
                            text: "Next Day Start",
                          },
                        },
                      ]
                    : [],
              },
            },
          });
        }
      } catch (err) {
        setError("Error fetching prices. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (selectedCountry !== "default") {
      fetchPrices();
    }
  }, [selectedCountry]);

  return (
    <Layout title="Market Prices">
        <h1 className="text-xl ml-5 font-bold text-start text-gray-700">
          Day-Ahead Electricity Prices
        </h1>
        <div className="m-5">
          <Select
            showSearch
            placeholder={"Select Country"}
            options={countries}
            loading={loading}
            // onSearch={handleSearch}
            onChange={(val:string) => setSelectedCountry(val)}
            value={countries.find((item:any) => item.value === selectedCountry)?.value}
        />
        </div>
        {error && <p className="text-center text-red-500 mt-4">{error}</p>}
        {!loading && !error && selectedCountry !== "default" && (
          <Chart options={chartData.options as any} series={chartData.series} type="line" height={350} />
        )}
        {loading && <p className="text-center text-gray-500 mt-10"><Spin/></p>}
    </Layout>
  );
};

export default MarketPrices;
