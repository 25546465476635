import { useEffect, useState } from "react";
import { SaveAllIcon, Settings2Icon, X } from "lucide-react";
import { Button } from "antd";
import toastr from "toastr";

import CardWidget from "./widgets/CardWidget";
import { client2 } from "../operationalRequest";
import CustomModal from "./CustomModal";
import EditCardWidgetComponent from "./widgets/forms/CardEditForm";
import GraphWidget from "./widgets/GraphWidget";
import EditGraphWidgetComponent from "./widgets/forms/GraphEditForm";
const { Responsive, WidthProvider } = require("react-grid-layout");

const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayoutProps {
    open: boolean;
}

const GridLayout = ({ open }: GridLayoutProps) => {
    const [layouts, setLayouts] = useState({
        lg: [],
    });

    const [layoutValues, setLayoutValue] = useState<
        { id: string; values: any }[]
    >([]);

    const [loading, setLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    let props: any;

    const defaultProps = {
        className: "layout",
        rowHeight: 60,
        cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    };

    props = { ...defaultProps, ...props };

    const handleRemoveLayout = (id: string) => {
        setLayouts((prevLayouts) => ({
            ...prevLayouts,
            lg: prevLayouts.lg.filter((item: any) => item.i !== id),
        }));

        setLayoutValue((prevLayoutValues) =>
            prevLayoutValues.filter((item) => item.id !== id),
        );
    };

    const handleModalOnSubmit = (values: any, id: string) => {
        setLayoutValue((prevLayoutValues) => {
            const existingIndex = prevLayoutValues.findIndex(
                (item) => item.id === id,
            );

            if (existingIndex !== -1) {
                return prevLayoutValues.map((item, index) =>
                    index === existingIndex ? { ...item, values } : item,
                );
            } else {
                return [...prevLayoutValues, { id, values }];
            }
        });

        toastr.success("Layout updated");
    };

    const generateDOM = () => {
        return layouts.lg.map((item: any, i: any) => (
            <div
                key={item.i}
                className="flex flex-col p-4 bg-gray-100 rounded w-full h-full overflow-hidden"
            >
                {open && (
                    <div className="flex justify-end close-btn">
                        <CustomModal title="Edit" icon={<Settings2Icon />}>
                            {item.i.startsWith("card") ? (
                                <EditCardWidgetComponent
                                    loading={loading}
                                    onSubmit={(values: any) =>
                                        handleModalOnSubmit(values, item.i)
                                    }
                                    initialValue={(() => {
                                        const cardData = layoutValues.find(
                                            (value) => value.id === item.i,
                                        );
                                        return cardData?.values;
                                    })()}
                                />
                            ) : (
                                <EditGraphWidgetComponent
                                    loading={loading}
                                    onSubmit={(values: any) =>
                                        handleModalOnSubmit(values, item.i)
                                    }
                                    initialValue={(() => {
                                        const cardData = layoutValues.find(
                                            (value) => value.id === item.i,
                                        );
                                        return cardData?.values;
                                    })()}
                                />
                            )}
                        </CustomModal>

                        <X
                            className="cursor-pointer hover:text-red-500"
                            onClick={() => handleRemoveLayout(item.i)}
                        />
                    </div>
                )}
                <div className="flex-grow">
                    {item.i.startsWith("card-widget-")
                        ? (() => {
                              const cardData = layoutValues.find(
                                  (value) => value.id === item.i,
                              );
                              return (
                                  <CardWidget
                                      title={
                                          cardData?.values?.title || "No Title"
                                      }
                                      kpi={cardData?.values?.kpi || ""}
                                      timePeriod={cardData?.values?.timePeriod || ""}
                                      description={
                                          cardData?.values?.description || ""
                                      }
                                  />
                              );
                          })()
                        : (() => {
                              const graphData = layoutValues.find(
                                  (value) => value.id === item.i,
                              );
                              return (
                                  <GraphWidget
                                      chartType={
                                          graphData?.values?.chartType || "bar"
                                      }
                                      xAxisTitle={
                                          graphData?.values?.xAxisTitle ||
                                          "No Title"
                                      }
                                      yAxisTitle={
                                          graphData?.values?.yAxisTitle ||
                                          "No Title"
                                      }
                                      dataType={
                                          graphData?.values?.dataType || ""
                                      }
                                  />
                              );
                          })()}
                </div>
            </div>
        ));
    };

    const handleLayoutChange = (layout: any) => {
        setLayouts((prevLayouts: any) => ({
            ...prevLayouts,
            lg: layout.filter((item: any) => item.i !== "__dropping-elem__"), // Remove placeholder
        }));
    };

    const handleDrop = (
        layout: any,
        layoutItem: any,
        event: React.DragEvent,
    ) => {
        const widgetValue = event.dataTransfer.getData("widget-type");
        const newItem = {
            i: `${widgetValue + "-" + Date.now()}`,
            x: layoutItem.x,
            y: layoutItem.y,
            w: 4,
            h: 3,
            widgetType: widgetValue,
        };

        setLayouts((prevLayouts: any) => ({
            ...prevLayouts,
            lg: [...prevLayouts.lg, newItem],
        }));
    };

    const fetchLayout = async () => {
        try {
            const response = await client2.get("api/dynamic-dashboards");
            if (response.data) {
                if (response.data.data && response.data.data.config) {
                    setLayouts(response.data.data.config.layouts || { lg: [] });
                    setLayoutValue(response.data.data.config.props || {});
                }
            }
            setIsLoaded(true);
        } catch (error) {
            console.error("Error fetching layouts:", error);
        }
    };

    useEffect(() => {
        fetchLayout();
    }, []);

    const saveLayout = async () => {
        try {
            await client2.post("api/dynamic-dashboards", {
                config: {
                    layouts,
                    props: layoutValues,
                },
            });
            toastr.success("Layouts saved successfully");
        } catch (error) {
            console.error("Error saving layouts:", error);
            toastr.error("Error saving layouts");
        }
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div className={`rounded-lg ${open ? "bg-gray-300" : ""}`}>
            {open && (
                <div className="flex justify-end p-2">
                    <Button type="default" onClick={() => saveLayout()}>
                        <SaveAllIcon className="w-full h-full" />
                    </Button>
                </div>
            )}
            <ResponsiveGridLayout
                {...props}
                layouts={layouts}
                onLayoutChange={handleLayoutChange}
                onDrop={handleDrop}
                isDroppable={true}
                draggableCancel=".close-btn"
                autoSize={true}
                style={{ minHeight: "150px" }}
                isDraggable={open}
                isResizable={open}
            >
                {generateDOM()}
            </ResponsiveGridLayout>
        </div>
    );
};

export default GridLayout;
