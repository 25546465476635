import React, { useState } from 'react'
import Layout from '../../components/layouts/Layout'
import OperationalGrid from '../../components/datatable/OperationalGrid'
import columns from './SystemColumn'
import PermissionCheck from '../../components/authorizeComponent/PermissionCheck'
import AddSystem from './AddSystem'
import { CREATE_SYSTEM } from '../../utils/permission'

const Systems = () => {

  const [mutate, setMutate] = useState({} as any);

  return (
    <Layout title="Systems">
      <OperationalGrid
        columns={columns(setMutate)}
        url={'api/systems'}
        context="systems"
        addBody={
          <PermissionCheck permission={CREATE_SYSTEM}>
              <AddSystem mutate={setMutate} />
          </PermissionCheck>
        }
        mutate={mutate}
        paginationOption={true}
      />
    </Layout>
  )
}

export default Systems
