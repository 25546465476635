import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { client2 } from '../../operationalRequest';
import { Badge, Button, DatePicker, DatePickerProps } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

const PvReport = () => {
    const [dateType, setDateType] = useState('day');
    const [date, setDate] = useState('' as any);
    const [data, setData] = useState({
        series: [
            { name: 'Load', data: [] },
            { name: 'PV Production', data: [] },
            { name: 'Grid', data: [] },
            { name: 'Battery', data: [] },
        ],
        options: {
            chart: {
                type: 'line',
                height: 350,
            },
            xaxis: {
                categories: [],
                title: {
                    text: dateType === 'day' ? 'Hour of Day' : 'Date',
                },
            },
            yaxis: {
                title: {
                    text: 'Energy (kW)',
                },
                labels: {
                    formatter: (value: any) =>
                        parseFloat(value).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                },
            },
            legend: {
                position: 'top',
            },
            colors: ['#FFBF00', '#008FFB', '#32a852', '#a8324e'],
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                y: {
                    formatter: (value: any) =>
                        parseFloat(value).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                },
            },
            stroke: {
                curve: 'smooth',
                width: [2, 2, 2, 2],
            },
        },
    } as any);

    const fetchData = async (dateType: string) => {
       try{
        const response = await client2.get('api/dashboard/pv-data?dateType=' + dateType + '&date=' + date);

        let pvAxis = [] as any;  // X-axis labels
        let formattedPvProduction = [] as any;
        let formattedLoad = [] as any;
        let formattedGrid = [] as any;
        let formattedBattery = [] as any;
        let text = "Hour of the day"

        if (dateType === 'day') {
            // Use hourly data for 'day'
            pvAxis = Array.from({ length: 24 }, (_, i) => `${i}:00`);
            formattedPvProduction = response?.data?.data?.pv_production ?? [];
            formattedLoad = response?.data?.data?.load ?? [];
            formattedGrid = response?.data?.data?.grid ?? [];
            formattedBattery = response?.data?.data?.battery ?? [];

        } else if (dateType === 'year') {
            // Use monthly data for 'year'
            const months = {
                '1': 'Jan', '2': 'Feb', '3': 'Mar', '4': 'Apr', '5': 'May', '6': 'Jun',
                '7': 'Jul', '8': 'Aug', '9': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec'
            } as any;

            pvAxis = Object.keys(response?.data?.data?.pv_production ?? []).map(month => months[month]);
            formattedPvProduction = Object.values(response?.data?.data?.pv_production ?? []);
            formattedLoad = Object.values(response?.data?.data?.load ?? []);
            formattedGrid = Object.values(response?.data?.data?.grid ?? []);
            formattedBattery = Object.values(response?.data?.data?.battery ?? []);
            text = "months"
        } else if (dateType === 'total') {
            // For 'total', show data between from_date and to_date
            const fromDate = dayjs(response?.data?.from_date).format('MMM DD');
            const toDate = dayjs(response?.data?.to_date).format('MMM DD');
            pvAxis = ['Total'];

            formattedGrid = [response?.data?.data['total_sum_by_type'].grid ?? 0];
            formattedBattery = [response?.data?.data['total_sum_by_type'].battery ?? 0];
            formattedPvProduction = [response?.data?.data['total_sum_by_type'].pv_production ?? 0];
            formattedLoad = [response?.data?.data['total_sum_by_type'].load ?? 0];

            text = ``
        } else {
            // Use daily data for 'week' or 'month'
            pvAxis = Object.keys(response?.data?.data?.pv_production ?? []).map(date => moment(date).format('MMM DD'));
            formattedPvProduction = Object.values(response?.data?.data?.pv_production ?? []);
            formattedLoad = Object.values(response?.data?.data?.load ?? []);
            formattedGrid = Object.values(response?.data?.data?.grid ?? []);
            formattedBattery = Object.values(response?.data?.data?.battery ?? []);
            text = "Days"
        }

        setData((prevState: any) => ({
            ...prevState,
            series: [
                { name: 'Load', data: formattedLoad },
                { name: 'PV Production', data: formattedPvProduction },
                { name: 'Grid', data: formattedGrid },
                { name: 'Battery', data: formattedBattery },
            ],
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: pvAxis,  // Set X-axis labels (hours for day, months for year, from_date-to_date for total)
                    title: {
                        text: text,
                    },
                },
            },
        }));
       }catch(e){
        
       }
    };

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        setDate(dateString);
    };

    useEffect(() => {
        fetchData(dateType);
    }, [dateType, date]);

    return (
        <>
            <div className='flex justify-start space-x-3'>
                <div>
                    {dateType === 'day' && <DatePicker defaultValue={dayjs().subtract(1, 'day')} onChange={onChange} />}
                    {dateType === 'week' && <DatePicker onChange={onChange} picker="week" />}
                    {dateType === 'month' && <DatePicker onChange={onChange} picker="month" />}
                    {dateType === 'year' && <DatePicker onChange={onChange} picker="year" />}
                </div>
                <div className="space-x-3 flex justify-start mt-1">
                    {['day', 'week', 'month', 'year', 'total'].map((type) => (
                        <div
                            key={type}
                            onClick={() => {
                                setDateType(type);
                                setDate('');
                            }}
                            className="cursor-pointer"
                        >
                            <span
                                className={`px-2 py-[2px] rounded-lg text-white text-xs 
                ${dateType === type ? 'bg-gray-500' : 'bg-blue-600'} 
                hover:bg-gray-400`}
                            >
                                {type.charAt(0).toUpperCase() + type.slice(1)}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <Chart options={data.options as any} series={data.series} type={dateType === 'total' ? 'bar' : 'line'} height={250} />
        </>
    );
};

export default PvReport;
