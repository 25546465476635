import React, { useState } from "react";
import { Modal } from "antd";
import { client2 } from "../../operationalRequest";
import toastr from "toastr";
import EditSystemComponent from "./EditSystemComponent";
import AddButton from "../../components/button/AddButton";

type AddSystemProps = {
  mutate?: any;
};

const AddSystem: React.FC<AddSystemProps> = ({ mutate }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onSubmit = async (values: any, form: any) => {
    try {
      if (loading) return;
      const response = await client2.post("api/systems", {
        system: values,
      });
      mutate({ data: response.data });
      setOpen(false);
      toastr.success("System Created Successfully");
      form.resetFields();
    } catch (e: any) {
      console.log(e);
    }
    setLoading((curr: any) => false);
  };

  return (
    <>
      <AddButton title="Add System" setOpen={setOpen}/>
      <Modal
        title={"Add System"}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        okButtonProps={{ style: { display: "none" } }}
      >
        <EditSystemComponent loading={loading} onSubmit={onSubmit} />
      </Modal>
    </>
  );
};

export default AddSystem;
