import React, { useState } from 'react'
import { Button, Tooltip, Checkbox, Form as CustomForm, type FormProps, Input, Select, Spin, Modal } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import LoadingButton from '../../components/LoadingButton';
import TextField from '../../components/formComponents/TextField';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { client2 } from '../../operationalRequest';
import SelectTable from '../../components/formComponents/SelectTable';
import toastr from "toastr";
import Details from './Details';
import { Trash } from 'lucide-react';
type EditAlitiqComponentProp = {
    initialValue?: any,
    onSubmit: any,
    loading: boolean
}
const EditAlitiqComponent: React.FC<EditAlitiqComponentProp> = ({ initialValue, onSubmit, loading }) => {
    const [enableForecast, setEnableForecast] = useState(!!initialValue.alitiq_enable)
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [form] = CustomForm.useForm();
    const [open, setOpen] = useState(false);

    const handleCheckboxChange = async (e: CheckboxChangeEvent, deviceId: string, enabled: boolean) => {
        const newCheckedValue = e.target.checked;
        try {
            const response = await client2.put(`api/system-device/${deviceId}/enable-alitiq`, { alitiq_enabled: enabled });
            setEnableForecast(enabled)
            toastr.success("Alitiq status updated successfully");
        } catch (error) {
            console.error("Error updating checkbox value:", error);
            toastr.error("Failed to update Alitiq status");
        }
    };

    // const handleValuesChange = (changedValues: any, allValues: any) => {
    //     if (changedValues.mover !== undefined) {
    //         const moverValue = parseInt(allValues.mover, 10);
    //         setShowAdditionalFields(moverValue > 1);
    //     }
    // };

    return (
        <>
            {!enableForecast ? (
                <>
                    <Checkbox checked={false} onChange={(e) => handleCheckboxChange(e, initialValue.system_device_id, true)}>
                        Enable Forecast
                    </Checkbox>
                </>
            ) : (

                <>
                    <Checkbox className='mb-5' checked={false} onChange={(e) => handleCheckboxChange(e, initialValue.system_device_id, false)}>
                        Disable Forecast
                    </Checkbox>

                    <CustomForm
                        form={form}
                        name="basic"
                        labelCol={{ span: 8 }}
                        initialValues={{
                            subsystem: [
                                {
                                  temp_factor: null,
                                  tilt: null,
                                  installed_power: null,
                                  installed_power_inverter: null,
                                  azimuth: null,
                                  mover: null,
                                },
                              ],
                            ...initialValue}}
                        onFinish={onSubmit}
                        
                        autoComplete="off"
                        layout="vertical"
                        // onValuesChange={handleValuesChange}
                    >
                        <TextField label="Latitude" name="latitude" type="number" info="The geographical coordinate indicating the North-South position of the site. Use decimal degrees." rules={[{ required: true, message: 'Please input latitude!' }]} />
                        <TextField label="Longitude" name="longitude" type="number" info="The geographical coordinate indicating the East-West position of the site. Use decimal degrees." rules={[{ required: true, message: 'Please input longitude!' }]} />

                        <CustomForm.List name="subsystem">
                            {(fields, { add, remove }) => (
                                <div>
                                    {fields.map((field, index) => (
                                        <div key={field.key} className='border p-2 border-dashed mt-3 rounded-md border-blue-400' >
                                            <TextField
                                                label="Temp Factor"
                                                name={[field.name, "temp_factor"]}
                                                type="number"
                                                info="Adjusts for temperature effects on PV system efficiency. Typical values: 0.035 (free-mounted), 0.05 (rooftop)."
                                                rules={[{ required: true, message: "Please input temp factor!" }]}
                                            />
                                            <TextField
                                                label="Tilt"
                                                name={[field.name, "tilt"]}
                                                type="number"
                                                info="The angle of the PV modules relative to the horizontal surface. Typically between 10° and 30°."
                                                rules={[{ required: true, message: "Please input tilt!" }]}
                                                // min={0}
                                            />
                                            <TextField
                                                label="Power"
                                                name={[field.name, "installed_power"]}
                                                type="number"
                                                info="The total installed DC power of the photovoltaic (PV) system, measured in kilowatts (kW)."
                                                rules={[
                                                    { required: true, message: "Please input installed power!" },
                                                ]}
                                                // min={0}
                                            />
                                            <TextField
                                                label="Inverter Power"
                                                name={[field.name, "installed_power_inverter"]}
                                                type="number"
                                                info="The total installed AC power of the PV system after passing through the inverter, measured in kilowatts (kW)."
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input installed power inverter!",
                                                    },
                                                ]}
                                                
                                            />
                                            <TextField
                                                label="Azimuth"
                                                name={[field.name, "azimuth"]}
                                                type="number"
                                                info="The direction the PV modules face: South = 180°, East = 90°, West = 270°, North = 0°."
                                                rules={[{ required: true, message: "Please input azimuth!" }]}
                                               
                                            />
                                            <TextField
                                                label="Mover"
                                                name={[field.name, "mover"]}
                                                type="number"
                                                info="Specifies the type of tracking system: 1 = No tracking, 2 = Vertical-axis, 3 = Horizontal-axis, 4 = Dual-axis."
                                                rules={[{ required: true, message: "Please input mover!" }]}
                                                min={0}
                                            />
                                            <CustomForm.Item
                                                noStyle
                                                shouldUpdate={(prevValues, currentValues) =>
                                                    prevValues?.subsystem?.[index]?.mover !== currentValues?.subsystem?.[index]?.mover
                                                }
                                            >
                                                {({ getFieldValue }) =>
                                                    getFieldValue(["subsystem", index, "mover"]) > 1 && (
                                                        <>
                                                            <TextField
                                                                label="Max Rotation Angle"
                                                                name={[field.name, "max_rotation_angle"]}
                                                                type="number"
                                                                info="For single-axis tracking systems, the maximum angle the modules can rotate."
                                                                rules={[{ required: true, message: "Please input max rotation angle!" }]}
                                                                min={0}
                                                            />
                                                            <TextField
                                                                label="Row Distance (m)"
                                                                name={[field.name, "row_distance"]}
                                                                type="number"
                                                                info="The distance between rows of PV module mounts, measured in meters."
                                                                rules={[{ required: true, message: "Please input row distance!" }]}
                                                                min={0}
                                                            />
                                                            <TextField
                                                                label="Table Length"
                                                                name={[field.name, "table_length"]}
                                                                type="number"
                                                                info="The length of the PV module table on each mount, measured in meters."
                                                                rules={[{ required: true, message: "Please input table length!" }]}
                                                                min={0}
                                                            />
                                                            <CustomForm.Item
                                                                label={
                                                                    <>
                                                                        Do Backtracking
                                                                        <Tooltip title="Enable backtracking to reduce shading during the PV system's operation.">
                                                                            <InfoCircleOutlined style={{ marginLeft: 8, color: "#1890ff" }} />
                                                                        </Tooltip>
                                                                    </>
                                                                }
                                                                name={[field.name, "do_backtracking"]}
                                                                valuePropName="checked"
                                                                rules={[{ required: true, message: "Please select backtracking option!" }]}
                                                            >
                                                                <Checkbox>Enable backtracking to reduce shading</Checkbox>
                                                            </CustomForm.Item>
                                                        </>
                                                    )
                                                }
                                            </CustomForm.Item>

                                            {/* Allow deletion only if more than one entry exists */}
                                            {fields.length > 1 && (
                                                <Button
                                                    type="link"
                                                    danger
                                                    onClick={() => remove(field.name)}
                                                    style={{ marginTop: "1.5rem" }}
                                                >
                                                    <Trash />
                                                </Button>
                                            )}

                                        </div>
                                    ))}

                                    {/* Add new entry button */}
                                    <Button
                                        type="dashed"
                                        onClick={() =>
                                            add({
                                                temp_factor: null,
                                                tilt: null,
                                                installed_power: null,
                                                installed_power_inverter: null,
                                                azimuth: null,
                                                mover: null,
                                            })
                                        }
                                        block
                                        icon={<PlusOutlined />}
                                        className='mt-3 mb-3'
                                    >
                                        Add Subsystem
                                    </Button>
                                </div>
                            )}
                        </CustomForm.List>

                        <CustomForm.Item>
                            <LoadingButton loading={loading} title={"Update"} />
                            <Button
                                type="link"
                                onClick={() => {
                                    setOpen(true);

                                }}
                                style={{ marginLeft: 10, textDecoration: 'underline' }}
                            >
                                More Details
                            </Button>
                            <Modal
                                title={'Details'}
                                open={open}
                                onOk={() => setOpen(false)}
                                onCancel={() => setOpen(false)}
                                width={800}
                                footer={null}
                            >
                                <Details setOpen={setOpen} />

                            </Modal>
                        </CustomForm.Item>
                    </CustomForm >
                </>
            )}
        </>
    )
}

export default EditAlitiqComponent