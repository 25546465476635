import { SYSTEM_TYPES } from '../../constants/types';
import Action from './Action';

const columns = (setMutate: (mutate: any) => void) => [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        filter: true,
        filterBy: {
            type: 'text'
        }

    },
    {
        title: "Created By",
        dataIndex: "created_by",
        key: "email",
        sorter: false,
        filter: true,
        filterBy: {
            type: 'table',
            table: 'companyUser',
            filters: []
        }
    },
    {
        title: "System Type",
        dataIndex: "type",
        key: "type",
        sorter: false,
        render: (_: any, record: any) => {
            const systemType = SYSTEM_TYPES.find(
                (type) => type.value === record.type
            );
            return systemType ? systemType.label : "Unknown";
        },
        filter: true,
        filterBy: {
            type: 'options',
            options: [
                { value: "2", label: "Energy System" },
                { value: "1", label: "PV Energy Generation" },
                { value: "3", label: "Energy Storage" },
                { value: "4", label: "Energy Consumption" },
                { value: "5", label: "Grid Point" },
            ]
        },
    },
    {
        title: "Action",
        dataIndex: "id",
        key: "action",
        render: (_: any, record: any) => {
            return (
                <Action setMutate={setMutate} record={record} />
            );
        },
    },
];

export default columns;
