import React, { useState, useEffect } from "react";
import { Button, Drawer, Tabs } from "antd";
import toastr from "toastr";
import { client2 } from "../../../operationalRequest";
import { ArrowBigRightDash } from "lucide-react";
import EditCompanyComponent from "./EditCompanyComponent";
import UserList from "./UserList";

type EditCompanyProps = {
    data: any;
    mutate?: any;
};
const EditCompany: React.FC<EditCompanyProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const editCompany = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true);
            const response = await client2.put(`api/company/${data?.id}`, {
                ...values,
            });
            if (response.status === 200) {
                mutate({ data: "data" });
                setOpen(false);
                toastr.success("Company Updated Successfully!");
            }
        } catch (e: any) {
            console.log(e);
        }
        setLoading((curr: any) => false);
    };

    return (
        <>
            <Button
                onClick={showDrawer}
                size="small"
                className={`bg-blue-500 size-8 rounded-full py-3 flex items-center`}
            >
                <ArrowBigRightDash />
            </Button>
            <Drawer size="large" title="Edit Company" onClose={onClose} open={open}>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: 'Company',
                            key: '1',
                            children: <EditCompanyComponent loading={loading} initialValue={{ ...data }} onSubmit={editCompany} />,
                        },
                        {
                            label: 'User',
                            key: '2',
                            children: <UserList companyId={data?.id} users={data?.users} />
                        }
                    ]}
                />
            </Drawer>
        </>
    );
};

export default EditCompany;