import React from "react";
import { Form, Input } from "antd";
import LoadingButton from "../../components/LoadingButton";
import _ from "lodash";
import TextArea from "antd/es/input/TextArea";
import CustomSelectOptions from "../../components/formComponents/CustomSelectOptions";
import SelectTable from "../../components/formComponents/SelectTable";
import { useSelector } from "react-redux";

type EditSystemComponentProps = {
    onSubmit: any;
    initialValue?: any;
    loading: boolean;
};

const EditSystemComponent: React.FC<EditSystemComponentProps> = ({
    onSubmit,
    initialValue,
    loading,
}) => {
    const [form] = Form.useForm();
    const { role } = useSelector((state: any) => state.role);

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={initialValue}
            onFinish={(values: any) => onSubmit(values, form)}
            autoComplete="off"
            layout="vertical"
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please input system name!" }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="System Type"
                name="type"
                rules={[{ required: true, message: "Please select the system type!" }]}
            >
                <CustomSelectOptions
                    options={[
                        { value: "2", label: "Energy System" },
                        {
                            value: "1", label: "PV Energy Generation",
                        },
                        { value: "3", label: "Energy Storage" },
                        { value: "4", label: "Energy Consumption" },
                        { value: "5", label: "Grid Point" },
                    ]}
                    disabled={form.getFieldValue("imported")}
                    onChange={(val: any) => {
                        form.setFieldValue("type", val)
                        form.setFieldValue("root_id", undefined)
                    }}
                />
            </Form.Item>
            <Form.Item noStyle dependencies={["type"]}>
                {({ getFieldValue }) => {
                    const type = getFieldValue("type");
                    return ['1', '3', '4', '5'].includes(type?.toString()) ? (
                        <Form.Item
                            label="System"
                            name="root_id"
                            rules={[
                                { required: false, message: "Please select the system!" },
                            ]}
                        >
                            <SelectTable
                             table="systems" 
                             filter={[{table: 'root_id', value: null}, (!!initialValue?.id && {table: 'id', eq:"!=", value:initialValue?.id})].filter(Boolean)}
                             />
                        </Form.Item>
                    ) : null;
                }}
            </Form.Item>

            {!role.role.includes('sub-tenant') && (
                <Form.Item
                    label="Assign To"
                    name="assign_to"
                    rules={[
                        { required: false, message: "Please select the assignee!" },
                    ]}
                >
                    <SelectTable
                        placeholder="Select User"
                        onChange={(val: any) => form.setFieldValue("assign_to", val)}
                        value={form.getFieldValue("assign_to")}
                        table="tenantUser"
                    />
                </Form.Item>
            )}
            <Form.Item
                label="Description"
                name={"description"}
                rules={[{ required: false, message: "Please input Description!" }]}
            >
                <TextArea rows={4} placeholder="Enter Description" />
            </Form.Item>

            <Form.Item>
                <LoadingButton
                    loading={loading}
                    title={_.isEmpty(initialValue) ? "Save" : "Update"}
                />
            </Form.Item>
        </Form>
    );
};

export default EditSystemComponent;
