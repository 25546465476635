import { Input, Pagination, Spin, Table, Form as CustomForm, TableColumnType, Select, Radio, Tooltip } from "antd";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { client2 } from "../../operationalRequest";
import _ from "lodash";
import CustomSelectOptions from "../formComponents/CustomSelectOptions";
import SelectTable from "../formComponents/SelectTable";
import { stringify } from "querystring";
import { ArrowDownWideNarrow, Filter, NotepadText } from "lucide-react";

interface OperationalGridProps {
  url: string;
  columns: any;
  scroll?: any;
  searchFilter?: boolean;
  addBody?: any;
  mutate?: any;
  setInfo?: any;
  context: string;
  pageSize?: number;
  sortOrder?: 'DESC' | 'ASC',
  paginationOption?: boolean;
}
const OperationalGrid: React.FC<OperationalGridProps> = ({
  url,
  columns,
  scroll = 500,
  searchFilter = true,
  addBody,
  mutate,
  setInfo,
  context,
  pageSize = 10,
  sortOrder = 'DESC',
  paginationOption = false
}) => {
  const [scrollLoad, setScrollLoad] = useState(false);
  const [data, setData] = useState([] as any);
  const [meta, setMeta] = useState({} as any);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: pageSize,
    sortOrder: sortOrder,
    sortProperty: "id",
  } as any);
  const [textSearch, setTextSearch] = useState("");
  const [filter, setFilter] = useState({} as any)
  const [pageMode, setPageMode] = useState(paginationOption? localStorage.getItem('apollo-pageMode') ?? 'pagination': 'pagination');

  const handleFilter = (index: string, value: any) => {
    setPagination({ ...pagination, page: 1 })
    setData([])
    setFilter((curr: any) => {
      const updatedFilter = { ...curr };

      if (value === null || value === undefined) {
        delete updatedFilter[index];
      } else {
        updatedFilter[index] = value;
      }

      return updatedFilter;
    });

  };


  // creating props for text search in filter
  const getColumnSearchProps = (dataIndex: string, filterBy: any): TableColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }: any) => (
      <div key={dataIndex} className="w-auto" onKeyDown={(e) => e.stopPropagation()}>
        {
          filterBy.type === 'text' && (
            <Input

              placeholder={`Search`}
              value={selectedKeys?.[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => handleFilter(dataIndex, selectedKeys?.[0])}
              style={{ marginBottom: 8, display: "block" }}
            />
          )
        }
        {
          filterBy.type === 'options' && (
            <CustomSelectOptions value={selectedKeys?.[0]} options={filterBy?.options} onChange={(value: any) => {
              handleFilter(dataIndex, value)
              setSelectedKeys(value)
            }} />
          )
        }
        {
          filterBy.type === 'table' && (
            <SelectTable value={selectedKeys?.[0]} table={filterBy?.table} filter={filterBy?.filters} onChange={(value: any) => {
              handleFilter(dataIndex, value)
              setSelectedKeys(value)
            }} />
          )
        }
      </div>
    ),
    filterIcon: (filtered: boolean) => {
      const isIndexed = dataIndex in filter;
      return (
        <FilterFilled size={6} className={`${isIndexed ? 'text-blue-600' : ''} font-extrabold`} />
      )
    },
  })

  // creating columns for filter
  const filterColumns = columns.map((item: any) => {
    let filters = {};
    if (item.filter) {


      filters = {
        ...getColumnSearchProps(item.dataIndex, item?.filterBy)
      }

    }
    return { ...item, ...(item?.filter && { ...filters }) }
  })

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await client2(
        `${url}?` +
        new URLSearchParams(pagination) +
        '&' +
        new URLSearchParams(
          Object.entries(filter).reduce((acc, [key, value]) => {
            if (value !== null && value !== undefined) {
              acc[`filter[${key}]`] = String(value);
            }
            return acc;
          }, {} as Record<string, string>)
        )
      );
      if (pageMode === "pagination") {
        setScrollLoad(false);
        setData(response?.data?.data?.[`${context}`]);
      } else {
        setScrollLoad(true);
        if (meta.current_page != meta.lastPage) { }
        setData((curr: any) => {
          const newData = [...curr, ...response?.data?.data?.[`${context}`]]
          return newData.filter(
            (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
          );
        });
      }
      setMeta(response?.data?.meta);
      setLoading(false);
      if (!!setInfo) {
        setInfo(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination, mutate, filter]);

  const handleSort = (pagi: any, filters: any, sorter: any) => {
    setPagination({
      ...pagination,
      sortOrder: sorter.order === "ascend" ? "ASC" : "DESC",
      sortProperty: sorter.column?.dataIndex ?? "created_at",
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      setData([])
      setPagination({ ...pagination, textSearch: textSearch, page: 1 });
    }
  };

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop >= clientHeight && !loading && pagination.page < meta?.last_page && pageMode === "scroll") {
      setLoading(true)
      setPagination((curr: any) => {
        return {
          ...curr,
          page: curr.page + 1
        }
      })
    }
  }, [loading, pagination, pageMode]);

  return (
    <div className="flex flex-col overflow-x-hidden">
      {
        paginationOption && (
          <>
            <br />
            <div className="flex justify-end mb-4">
              <Radio.Group size="small" value={pageMode} onChange={(e: any) => {
                localStorage.setItem('apollo-pageMode', e.target.value)
                setPageMode((curr: any) => e.target.value)
                setData([]);
                setPagination({ ...pagination, page: 1 })
              }}>
                <Tooltip title="Pagination mode">
                  <Radio.Button value="pagination"><NotepadText size={15} className="mt-1" /></Radio.Button>
                </Tooltip>
                <Tooltip title="Scroll mode">
                  <Radio.Button value="scroll"><ArrowDownWideNarrow size={15} className="mt-1" /></Radio.Button>
                </Tooltip>
              </Radio.Group>
            </div>
          </>
        )
      }

      <div className="flex justify-between">
        <div className="flex justify-start space-x-3">
          {searchFilter && (
            <>
              <Input
                className="max-w-[300px] mb-5"
                placeholder="Search..."
                value={textSearch}
                onKeyDown={handleKeyDown}
                onChange={(e: any) => setTextSearch(e.target.value)}
              />
              {pagination?.textSearch && (
                <span>Search Key: {pagination?.textSearch}</span>
              )}
            </>
          )}
        </div>
        <div className="flex justify-end space-x-2 mr-3">
          {!!addBody && addBody}
        </div>
      </div>
      <Table
        pagination={false}
        scroll={{ y: scroll }}
        loading={{
          spinning: !scrollLoad && loading,
          indicator: <Spin size="large" style={{ color: "green" }} />,
        }}
        className="w-full drop-shadow-sm shadow-md"
        dataSource={data}
        columns={filterColumns}
        footer={() => (
          <div className="flex justify-center items-center">
            {pageMode === 'pagination' && <Pagination
              onChange={(page: any, pageSize: any) =>
                setPagination({ ...pagination, page: page, pageSize })
              }
              className="flex justify-end"
              defaultCurrent={pagination.page}
              total={meta?.total}
              pageSize={pagination.pageSize}
            />}
            {loading && pageMode === 'scroll' && (
              <Spin />
            )}
            {meta.last_page === meta.current_page && pageMode === 'scroll' && !loading && (
              <div>
                End of page
              </div>
            )}
          </div>
        )}
        rowKey={(record: any) => record.id}
        onChange={handleSort}
        onScroll={handleScroll}
      />
    </div>
  );
};

export default OperationalGrid;
