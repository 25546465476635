import React, { useEffect, useState } from "react";
import { client2 } from "../../operationalRequest";
import { KPI_OPTIONS } from "../../constants/widgetsTypes";

interface IncrementColors {
    positive: string;
    negative: string;
}

interface CardWidgetProps {
    title?: string;
    value?: number | null;
    unit?: string;
    description?: string;
    increment?: number | null;
    incrementSuffix?: string;
    incrementColors?: IncrementColors;
    cardType?: string;
    kpi: string;
    timePeriod?: string;
}

const CardWidget: React.FC<CardWidgetProps> = ({
    title,
    kpi,
    timePeriod,
    incrementSuffix = "%",
    description,
    incrementColors = { positive: "bg-green-200", negative: "bg-red-200" },
}) => {
    const [displayValue, setDisplayValue] = useState<number | null>();
    const [displayUnit, setDisplayUnit] = useState<string | null>();
    const [displayIncrement, setDisplayIncrement] = useState<number | null>(
        null,
    );

    // FECTCH API TO GET VALUE OF REQUIRED SELECT TYPE
    const fetchData = async () => {
        try {            
            const response = await client2.get(`api/widgets/reports?kpi=${kpi}&timePeriod=${timePeriod}`);
            const data = response?.data?.data;

            const currentUnit = KPI_OPTIONS.find(
                (option: any) => option.value === kpi,
            )?.unit;

            setDisplayValue(data?.currentTotal);
            setDisplayUnit(currentUnit || "");
            setDisplayIncrement(data?.percentageDifference || 0);
        } catch (e) {
            console.error("Error fetching data:", e);
        }
    };

    useEffect(() => {
        fetchData();
    }, [kpi, timePeriod]); 

    return (
        <div className="h-full w-full border-0 shadow-none">
            <span>{title}</span>
            <div className="flex space-x-1 mt-2">
                <h5 className="font-semibold text-lg">
                    {displayValue !== null && displayValue !== undefined
                        ? displayValue.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                        : 0}{" "}
                    {displayUnit}
                </h5>
                {displayIncrement !== null &&
                    displayIncrement !== undefined && (
                        <small
                            className={`${
                                displayIncrement < 0
                                    ? incrementColors.negative
                                    : incrementColors.positive
                            } px-1 my-auto`}
                        >
                            {displayIncrement.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                            {incrementSuffix}
                        </small>
                    )}
            </div>
            <small>{description}</small>
        </div>
    );
};

export default CardWidget;
