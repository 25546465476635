import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";

import CustomSelectOptions from "../../formComponents/CustomSelectOptions";
import {  KPI_OPTIONS, TIME_PERIOD_OPTIONS } from "../../../constants/widgetsTypes";
import LoadingButton from "../../LoadingButton";

type EditCardWidgetComponentProps = {
    onSubmit: any;
    initialValue?: any;
    loading: boolean;
};

const EditCardWidgetComponent: React.FC<EditCardWidgetComponentProps> = ({
    onSubmit,
    loading,
    initialValue,
}) => {
    const [form] = Form.useForm();

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={(values: any) => onSubmit(values, form)}
            initialValues={initialValue}
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please input the title!" }]}
            >
                <Input placeholder="Enter title" />
            </Form.Item>
            <div className="flex flex-row gap-4 mb-4"> 
            <Form.Item
                label="KPI"
                name="kpi"
                rules={[
                    {
                        required: true,
                        message: "Please select the KPI!",
                    },
                ]}
                className="w-full" 
            >
                <CustomSelectOptions
                    options={KPI_OPTIONS}
                    onChange={(val: any) => form.setFieldValue("kpi", val)}
                />
            </Form.Item>
            <Form.Item
                label="Time Period"
                name="timePeriod"
                rules={[
                    {
                        required: true,
                        message: "Please select the time period!",
                    },
                ]}
                className="w-full" 
            >
                <CustomSelectOptions
                    options={TIME_PERIOD_OPTIONS}
                    onChange={(val: any) => form.setFieldValue("timePeriod", val)}
                />
            </Form.Item>
            </div>
            
            <Form.Item label="Description" name="description">
                <Input.TextArea placeholder="Enter description" rows={3} />
            </Form.Item>
            <Form.Item>
                <LoadingButton
                    loading={loading}
                    title={_.isEmpty(initialValue) ? "Save" : "Update"}
                />
            </Form.Item>
        </Form>
    );
};

export default EditCardWidgetComponent;
