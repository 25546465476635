export interface IWidget {
    label: string;
    value: string;
    img?: string;
    layout?: LayoutItem;
}

interface IncrementColors {
    positive: string;
    negative: string;
}

interface CardWidgetProps {
    title?: string;
    value?: number | null;
    unit?: string;
    description?: string;
    increment?: number | null;
    incrementSuffix?: string;
    incrementColors?: IncrementColors;
    type?: string;
}

interface GraphWidgetProps {
    chartType: "bar" | "line" | "pie";
    xAxisTitle: string;
    yAxisTitle: string;
    stacked?: boolean;
    height?: number | string;
    width?: number | string;
    legend?: string;
    dataLabel?: boolean;
    toolbar?: boolean;
    dateFilter?: boolean;
}

export interface LayoutItem {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    widgetType: string;
    widgetProps: CardWidgetProps | GraphWidgetProps;
}

export const WIDGET_TYPES: IWidget[] = [
    {
        label: "Card Widget",
        value: "card-widget",
        img: "/img/CARD_IMAGE.jpg",
    },
    {
        label: "Graph Widget",
        value: "graph-widget",
        img: "/img/GRAPH_IMAGE.jpg",
    },
];
