import { Input, Pagination, Spin, Table, Form as CustomForm } from "antd";
import React, { useEffect, useState } from "react";
import { client2 } from "../../operationalRequest";
import _ from "lodash";

interface OperationalDatatableProps {
  url: String;
  query?: any;
  columns: any;
  scroll?: any;
  filters?: any;
  searchFilter?: boolean;
  heading?: string;
  addBody?: any;
  mutate?: any;
  setInfo?: any;
  bulkupload?: any;
}
const OperationalDatatable: React.FC<OperationalDatatableProps> = ({
  url,
  query,
  columns,
  scroll = 500,
  filters,
  searchFilter = true,
  heading,
  addBody,
  mutate,
  setInfo,
  bulkupload,
}) => {
  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 5,
    sortOrder: "DESC",
    sortProperty: "id",
    ...filters,
  } as any);
  const [textSearch, setTextSearch] = useState("");
  const [csvData, setCsvData] = useState({} as any);
  const [toggle, setToggle] = useState(false);
  const [changed, setChanged] = useState({} as any);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await client2(
        `${url}?` + new URLSearchParams(pagination)
      );
      setData(response?.data);
      setLoading(false);
      if (!!setInfo) {
        setInfo(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination, mutate, changed]);

  const handleSort = (pagi: any, filters: any, sorter: any) => {
    setPagination({
      ...pagination,
      sortOrder: sorter.order === "ascend" ? "ASC" : "DESC",
      sortProperty: sorter.column?.dataIndex ?? "created_at",
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      setPagination({ ...pagination, textSearch: textSearch });
    }
  };

  return (
    <div className="flex flex-col overflow-x-hidden">
      <br />
      <div className="flex justify-between">
        <div className="flex justify-start space-x-3">
          {searchFilter && (
            <>
              <Input
                className="max-w-[300px] mb-5"
                placeholder="Search..."
                value={textSearch}
                onKeyDown={handleKeyDown}
                onChange={(e: any) => setTextSearch(e.target.value)}
              />
              {pagination?.textSearch && (
                <span>Search Key: {pagination?.textSearch}</span>
              )}
            </>
          )}
        </div>
        <div className="flex justify-end space-x-2 mr-3">
          {!!addBody && addBody}
          {!!bulkupload && bulkupload}
        </div>
      </div>
      <Table
        pagination={false}
        scroll={{ y: scroll }}
        loading={{
          spinning: loading,
          indicator: <Spin size="large" style={{ color: "green" }} />,
        }}
        className="w-full drop-shadow-sm shadow-md"
        dataSource={data?.data?.data}
        columns={columns}
        footer={() => (
          <Pagination
            onChange={(page: any, pageSize: any) =>
              setPagination({ ...pagination, page: page, pageSize })
            }
            className="flex justify-end"
            defaultCurrent={pagination.page}
            total={data?.data?.total}
            pageSize={pagination.pageSize}
          />
        )}
        rowKey={(record: any) => record.id}
        onChange={handleSort}
      />
    </div>
  );
};

export default OperationalDatatable;
