import React, { useState } from "react";
import { Drawer, Tabs } from "antd";
import toastr from "toastr";
import { client2 } from "../../operationalRequest";
import EditSystemComponent from "./EditSystemComponent";
import EditButton from "../../components/button/EditButton";

type EditSystemProps = {
  data: any;
  mutate?: any;
};
const EditSystem: React.FC<EditSystemProps> = ({ data, mutate }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const editSystem = async (values: any) => {
    try {
      if (loading) return;
      setLoading((curr: any) => true);
      const response = await client2.put(`api/systems/${data?.id}`, {
        system: values,
      });
      if (response.status === 200) {
        mutate({ data: "data" });
        setOpen(false);
        toastr.success("System Updated Successfully!");
      }
    } catch (e: any) {
      console.log(e);
    }
    setLoading((curr: any) => false);
  };

  return (
    <>
      <EditButton onClick={showDrawer} title="Edit System"/>
      <Drawer size="large" title="Edit System" onClose={onClose} open={open}>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "System",
              key: "1",
              children: (
                <EditSystemComponent
                  loading={loading}
                  initialValue={{ ...data, system_id: !!data.system_id ? `${data.system_id}|${data.system_string_type}` : null }}
                  onSubmit={editSystem}
                />
              ),
            },
          ]}
        />
      </Drawer>
    </>
  );
};

export default EditSystem;
