import { Form, Input, Tooltip } from 'antd';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

type TextFieldProps = {
  label: string;
  name: any;
  rules: any;
  type?: string;
  info?: string;
  min?: number;
};

const TextField: React.FC<TextFieldProps> = ({ label, name, rules, type = "text", info, min }) => {
  return (
    <Form.Item
      label={
        <span>
          {label}
          {info && (
            <Tooltip title={info}>
              <InfoCircleOutlined className="ml-2 text-gray-500 cursor-pointer" />
            </Tooltip>
          )}
        </span>
      }
      name={name}
      rules={rules}
      className="w-full"
    >
      <Input type={type} min={min}/>
    </Form.Item>
  );
};

export default TextField;
