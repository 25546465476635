import { Button, Modal } from 'antd'
import { BaseButtonProps } from 'antd/es/button/button'
import React, { useState } from 'react'


type CustomModalProps = {
    icon?: string | React.ReactNode,
    onOk?: void,
    title: string,
    children: React.ReactNode,
    customCss?: string,
}
const CustomModal: React.FC<CustomModalProps> = ({ icon, onOk, title, children, customCss }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <a className={customCss} onClick={() => setOpen(true)}>
                {icon}
            </a>
            <Modal
                title={title}
                open={open}
                onOk={() => onOk ?? setOpen(false)}
                onCancel={() => setOpen(false)}
                width={800}
                okButtonProps={{ style: { display: 'none' } }}
            >
                {children}
            </Modal>
        </>
    )
}

export default CustomModal