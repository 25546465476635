import { Button, Tooltip } from 'antd';
import { ArrowBigRightDash } from 'lucide-react';
import React from 'react'

type EditButtonProps = {
    title: string;
    onClick: any
}
const EditButton: React.FC<EditButtonProps> = ({title, onClick}) => {
  return (
    <Tooltip title={title}>
        <Button
          onClick={onClick}
          size="small"
          className={`bg-blue-500 size-8 rounded-full py-3 flex items-center`}
        >
          <ArrowBigRightDash />
        </Button>
      </Tooltip>
  )
}

export default EditButton