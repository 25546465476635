import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import { useState } from "react";

interface CustomDatePickerProps {
    onDateChange: (dataType:string, date: string | string[]) => void;
}

const CustomDatePicker=  ({ onDateChange }: CustomDatePickerProps) => {
    const dateTypes = ["day", "week", "month", "year", "total"];
    const [dateType, setDateType] = useState('day');
    const [date, setDate] = useState('' as any);

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        setDate(dateString);
        onDateChange(dateType, dateString);
    };

    return (
        <div className='flex justify-start space-x-3'>
            <div>
            {dateType === 'day' && <DatePicker defaultValue={dayjs().subtract(1, 'day')} onChange={onChange} />}
            {dateType === 'week' && <DatePicker onChange={onChange} picker="week" />}
            {dateType === 'month' && <DatePicker onChange={onChange} picker="month" />}
            {dateType === 'year' && <DatePicker onChange={onChange} picker="year" />}
            </div>
            <div className="space-x-3 flex justify-start mt-1">
                {dateTypes.map((type) => (
                    <div
                        key={type}
                        onClick={() => {
                            setDateType(type);
                            setDate('');
                            onDateChange(dateType, "");
                        }}
                        className="cursor-pointer"
                    >
                        <span
                            className={`px-2 py-[2px] rounded-lg text-white text-xs 
                            ${dateType === type ? 'bg-gray-500' : 'bg-blue-600'} 
                            hover:bg-gray-400`}
                        >
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CustomDatePicker;
