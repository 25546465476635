import React, { ReactNode } from "react";

interface DrawerProps {
    open: boolean;
    position?: "left" | "right";
    width?: string | number;
    children: ReactNode;
}

const CustomDrawer: React.FC<DrawerProps> = ({
    open,
    position = "right",
    width = "150px",
    children,
}) => {
    return (
        <div
            className={`fixed top-12 h-full bg-white shadow-lg transition-transform ${
                position === "right" ? "right-0" : "left-0"
            } ${open ? "translate-x-0" : position === "right" ? "translate-x-full" : "-translate-x-full"}`}
            style={{ width }}
        >
            <div className="relative p-4">{children}</div>
        </div>
    );
};

export default CustomDrawer;
