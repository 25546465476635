import { Input, Pagination, Spin, Table, Form as CustomForm } from "antd";
import React, { useRef, useEffect, useState } from "react";
import { client2 } from "../../operationalRequest";
import _ from "lodash";
import FilterAlert from "../../components/datatable/filter/FilterAlert";
import FilterPageMode from "../../components/datatable/filter/FilterPageMode";

interface FilterDatatableProps {
  url: String;
  query?: any;
  columns: any;
  scroll?: any;
  filters?: any;
  searchFilter?: boolean;
  heading?: string;
  addBody?: any;
  mutate?: any;
  setInfo?: any;
  bulkupload?: any;
  columnFilter?: any;
  setColumnFilter?: any;
}
const FilterDatatable: React.FC<FilterDatatableProps> = ({
  url,
  query,
  columns,
  scroll = 500,
  filters,
  searchFilter = true,
  heading,
  addBody,
  mutate,
  setInfo,
  bulkupload,
  columnFilter,
  setColumnFilter,
}) => {
  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 5,
    sortOrder: "DESC",
    sortProperty: "id",
    ...filters,
  } as any);
  const [textSearch, setTextSearch] = useState("");
  const [changed, setChanged] = useState({} as any);
  const [size, setSize] = useState("pagination");
  const [isActive, setIsActive] = useState(false);
  const [atBottom, setAtBottom] = useState<boolean>(false);
  const [infiniteScrollMode, setInfiniteScrollMode] = useState(false);
  const [loadingInfiniteScroll, setLoadingInfiniteScroll] = useState(false);
  const pageKey = `viewMode_${window.location.pathname}`;

  const fetchData = async () => {
    setLoading(true);
    try {
      const searchParams = new URLSearchParams({
        ...pagination,
        ...(columnFilter
          ? { columnFilterValue: JSON.stringify(columnFilter) }
          : {}),
      });
      const response = await client2(`${url}?${searchParams.toString()}`);
      setData(response?.data);
      setLoading(false);
      if (!!setInfo) {
        setInfo(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const savedMode = localStorage.getItem(pageKey) || "pagination";
    setSize(savedMode);
    if (savedMode === "loading") {
      setIsActive(true);
      setInfiniteScrollMode(true);
      fetchDataLoading(10);
    } else {
      setIsActive(false);
      setInfiniteScrollMode(false);
      fetchData();
    }
  }, [pagination, mutate, changed]);

  const handleSort = (pagi: any, filters: any, sorter: any) => {
    setPagination({
      ...pagination,
      sortOrder: sorter.order === "ascend" ? "ASC" : "DESC",
      sortProperty: sorter.column?.dataIndex ?? "created_at",
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      setPagination({ ...pagination, textSearch: textSearch, page:0 });
    }
  };

  const handleAlertClose = (index: number, filterKey: any) => {
    const filterValue = `filter_${window.location.pathname}`;
    const existingFilters = localStorage.getItem(filterValue);
    if (existingFilters) {
      const updatedFilters = JSON.parse(existingFilters);
      if (filterKey in updatedFilters) {
        delete updatedFilters[filterKey];
      }
      if (Object.keys(updatedFilters).length === 0) {
        localStorage.removeItem(filterValue);
      } else {
        localStorage.setItem(filterValue, JSON.stringify(updatedFilters));
      }
      if (typeof setColumnFilter === "function") {
        setColumnFilter(updatedFilters);
      }
      fetchFilterData(updatedFilters);
    }
  };

  const fetchFilterData = async (updatedFilters: any) => {
    const getMode = localStorage.getItem(pageKey);
    if (getMode === "loading") {
      setLoadingInfiniteScroll(true);
    } else {
      setLoading(true);
    }
    try {
      if (getMode === "loading") {
        const loadingMore = {
          page: 0,
          pageSize: 10,
          sortOrder: "DESC",
          sortProperty: "created_at",
          ...filters,
        };
        const searchParams = new URLSearchParams({
          ...loadingMore,
          ...(updatedFilters && Object.keys(updatedFilters).length > 0
            ? { columnFilterValue: JSON.stringify(updatedFilters) }
            : {}),
        });
        const response = await client2(`${url}?${searchParams.toString()}`);
        setData(response?.data);
        setLoadingInfiniteScroll(false);
        if (!!setInfo) {
          setInfo(response?.data);
        }
      } else {
        const searchParams = new URLSearchParams({
          ...pagination,
          ...(updatedFilters && Object.keys(updatedFilters).length > 0
            ? { columnFilterValue: JSON.stringify(updatedFilters) }
            : {}),
        });
        const response = await client2(`${url}?${searchParams.toString()}`);
        setData(response?.data);
        setLoading(false);
        if (!!setInfo) {
          setInfo(response?.data);
        }
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  let sizeCount = 5;
  const handleModeChange = (event: any) => {
    const setMode = event.target.value;
    setSize(setMode);
    localStorage.setItem(pageKey, setMode);
    setInfiniteScrollMode(setMode === "loading");
    if (setMode === "pagination") {
      setIsActive(false);
      setInfiniteScrollMode(false);
      fetchData();
    } else if (setMode === "loading") {
      setIsActive(true);
      setInfiniteScrollMode(true);
      sizeCount += 5;
      fetchDataLoading(sizeCount);
    }
  };

  const fetchDataLoading = async (sizeCount: number) => {
    setLoadingInfiniteScroll(true);
    try {
      const loadingMore = {
        page: 0,
        pageSize: sizeCount,
        sortOrder: "DESC",
        sortProperty: "created_at",
        ...filters,
      };
      const searchParams = new URLSearchParams({
        ...loadingMore,
        ...(columnFilter
          ? { columnFilterValue: JSON.stringify(columnFilter) }
          : {}),
      });
      const response = await client2(`${url}?${searchParams.toString()}`);
      const total = response.data.data.total;
      const to = response.data.data.to;
      const sizeCountRem = sizeCount - 5;
      if (to >= total || sizeCountRem >= total) {
        setLoadingInfiniteScroll(false);
        setLoading(false);
        setAtBottom(false);
        removeScrollListener();
      }
      setData(response?.data);
      setLoadingInfiniteScroll(false);
      if (!!setInfo) {
        setInfo(response?.data);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const divElement = document.querySelector(
    ".page_container"
  ) as HTMLDivElement | null;

  const handleScroll = () => {
    if (divElement) {
      const scrollTop = divElement.scrollTop;
      const scrollHeight = divElement.scrollHeight;
      const clientHeight = divElement.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        setAtBottom(true);
        if (sizeCount === 5) {
          sizeCount = 10;
        }
        sizeCount = sizeCount + 5;
        fetchDataLoading(sizeCount);
      } else {
        setAtBottom(false);
      }
    }
  };

  const removeScrollListener = () => {
    const divElement = document.querySelector(
      ".page_container"
    ) as HTMLDivElement | null;
    if (divElement) {
      divElement.removeEventListener("scroll", handleScroll);
    }
    const loadingIcon = document.querySelector(
      ".ant-table-footer"
    ) as HTMLDivElement | null;
    if (loadingIcon) {
      if (!document.querySelector(".no-more-data-message")) {
        const noMoreDataMessage = document.createElement("div");
        noMoreDataMessage.className =
          "no-more-data-message flex justify-center items-center";
        Object.assign(noMoreDataMessage.style, {
          color: "gray",
          background: "#fafafa",
        });
        noMoreDataMessage.innerHTML = "No more!";
        loadingIcon.insertAdjacentElement("afterend", noMoreDataMessage);
        setTimeout(() => {
          noMoreDataMessage.remove();
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (isActive) {
      if (divElement) {
        divElement?.addEventListener("scroll", handleScroll);
      }
      return () => {
        divElement?.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isActive, divElement]);

  return (
    <div className="flex flex-col overflow-x-hidden">
      <br />
      <div className="flex justify-between">
        <div className="flex justify-start space-x-3">
          {searchFilter && (
            <>
              <Input
                className="max-w-[300px] mb-5"
                placeholder="Search..."
                value={textSearch}
                onKeyDown={handleKeyDown}
                onChange={(e: any) => setTextSearch(e.target.value)}
              />
              {pagination?.textSearch && (
                <span>Search Key: {pagination?.textSearch}</span>
              )}
            </>
          )}
        </div>
        <div className="flex justify-end space-x-2 mr-3">
          {!!addBody && addBody}
          {!!bulkupload && bulkupload}
        </div>
      </div>
      <div className="filter-bar" style={{ display: "flex" }}>
        <FilterAlert
          columnFilter={columnFilter}
          handleAlertClose={handleAlertClose}
        />
        <FilterPageMode size={size} handleModeChange={handleModeChange} />
      </div>
      <Table
        pagination={false}
        // scroll={{ y: scroll }}
        loading={{
          // spinning: loading,
          spinning: !infiniteScrollMode && loading,
          indicator: <Spin size="large" style={{ color: "green" }} />,
        }}
        className="w-full drop-shadow-sm shadow-md"
        key={JSON.stringify(columnFilter)}
        dataSource={data?.data?.data}
        columns={columns}
        footer={() => (
          <div className="flex justify-center items-center">
            {!infiniteScrollMode && (
              <Pagination
                onChange={(page: any, pageSize: any) =>
                  setPagination({ ...pagination, page: page, pageSize })
                }
                className="flex justify-end"
                defaultCurrent={pagination.page}
                total={data?.data?.total}
                pageSize={pagination.pageSize}
              />
            )}
            {infiniteScrollMode && loadingInfiniteScroll && (
              <Spin
                size="large"
                className="justify-center items-center"
                style={{
                  marginLeft: "10px",
                  color: "green",
                }}
              />
            )}
          </div>
        )}
        rowKey={(record: any) => record.id}
        onChange={handleSort}
      />
    </div>
  );
};

export default FilterDatatable;
