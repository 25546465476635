import { Button, Drawer, Tabs } from 'antd';
import { ArrowBigRightDash } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import EditStoreyComponent from './EditIotComponent';
import { client } from '../../useRequest';
import toastr from "toastr";
import { client2 } from '../../operationalRequest';
import EditAttributeComponents from './EditAttributeComponent';
import EditIotComponent from './EditIotComponent';
import EditAlitiqComponent from './EditAlitiqComponent';

type EditIotProps = {
    data: any
    mutate?: any
}
const EditIot: React.FC<EditIotProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [attributes, setAttributes] = useState({} as any);
    const [loading, setLoading] = useState(false)

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const editIot = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true)
            const response = await client2.put(`api/system-device/${data?.id}`, {
                device: values
            })
            console.log(response.data)
            if (response.status === 200) {
                mutate({ data: 'data' });
                setOpen(false)
                toastr.success("Device Updated Successfully");
            }
        } catch (e: any) {
            console.log(e)
        }
        setLoading((curr: any) => false)
    }

    const editAttribute = async (values: any) => {
        try {

            if (loading) return;
            setLoading((curr: any) => true)
            const response = await client2.put(`api/system-device/${data?.id}`, {
                ...values
            })
            if (response.status === 200) {
                mutate({ data: response.data });
                setOpen(false)
                toastr.success("Device Attribute Updated Successfully");
            }
        } catch (e: any) {
            console.log(e)
        }
        setLoading((curr: any) => false)
    }

    const editAlitiq = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true);
            let response = {} as any;
            if(!values.id){
                response = await client2.post(`api/alitiq`, {
                    ...values,
                    system_device_id: data.id
                });
            }else{
                // response = await client2.put(`api/alitiq/${data?.id}`, {
                //     ...values,
                //     system_device_id: data.id
                // });
                toastr.error("Update is disabled. Please contact provider.");
            }
            
            if (response.status === 200) {
                mutate({ data: "data" });
                setOpen(false);
                toastr.success("Forecast Updated Successfully!");
            }
        } catch (e: any) {
            console.log(e);
        }
        setLoading((curr: any) => false);
    }

    let items = [
        {
            label: 'Device',
            key: '1',
            children: (
                <EditIotComponent
                    loading={loading}
                    initialValue={{
                        ...data,
                        location_id: data.location_id && data.location_type
                            ? `${data.location_id}|${data.deviceable_string_type}`
                            : null
                    }}
                    onSubmit={editIot}
                />
            ),
        },
        // You can uncomment and use the Attributes section if needed
        // {
        //     label: 'Attributes',
        //     key: '2',
        //     children: <EditAttributeComponents loading={loading} initialValue={{ attributes: data.attributes }} onSubmit={editAttribute} />,
        // },
    ]

    if (data?.device_type === 10 || data?.device_type === 70) {
        items.push(
            {
                label: 'Forecast',
                key: '2',
                children: <EditAlitiqComponent loading={loading} initialValue={{ system_device_id: data.id, alitiq_enable: data.alitiq_enable, ...data.location?.attributes, ...data.alitiq, ...(!!data?.alitiq?.config && { subsystem: data.alitiq.config }) }} onSubmit={editAlitiq} />,
            }
        )
    }

    // useEffect(() => {

    // },[data])
    return (
        <>
            <Button onClick={showDrawer} size='small' className={`bg-blue-500 size-8 rounded-full py-3 flex items-center`} ><ArrowBigRightDash /></Button>
            <Drawer size='large' title="Edit Device" onClose={onClose} open={open}>
                <Tabs
                    defaultActiveKey="1"
                    items={items}
                />

            </Drawer>
        </>
    );
}

export default EditIot
